/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h3: "h3",
    em: "em",
    br: "br"
  }, _provideComponents(), props.components), {SideBySide, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "10-most-dangerous-jobs-for-your-ears",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#10-most-dangerous-jobs-for-your-ears",
    "aria-label": "10 most dangerous jobs for your ears permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "10 most dangerous jobs for your ears"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/man-hero.png",
    alt: "two friends using hearing aids smiling",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If programmers and administrators risk carpal tunnel and lower back problems from sitting and typing for much too long, then what about other occupations?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "These side effects — let’s call them “occupational hazards” — might seem obvious. In contrast, and as an antidote, a profession where individuals get to move around and be active might seem to pose less health risks and actually help the body move."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "But what about the hidden risks? In fact, there are professions that put our safety in danger — and these are less obvious issues, like our eyesight and hearing levels. Overtime, the costs to many of these professions that provide their workers with passion-filled days of doing work they love can be severe."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing is one of those five senses we take for granted but its effects can be long-ranging, affecting workers over time. The economic burden of hearing loss — especially those from insurance claims and workplace issues, along with lost worker productivity — results in $300,000 over the lifetime of an individual."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Don’t let those numbers scare you. Just be in the know: here are the top 10 most dangerous professions for your hearing — and a few may just surprise you."), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/airplane.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "1-airport-staff",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#1-airport-staff",
    "aria-label": "1 airport staff permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.em, null, "1. Airport Staff")), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "Ground support staff, especially those who are constantly shuttling vehicles and baggage on the tarmac, are constantly exposed to loud engines of the aircraft as well as having to strain to communicate across the noise.")), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "Jet engines emit up to 140 dB of sound and the level at which hearing loss begins to occur is constant exposure to sounds 85 dB or higher.")), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "It is both the pressure of the engines, the force they physically emit as well as the sound that can deteriorate one’s hearing over time."))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/concert.png"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "2-entertainment-venue-and-tour-managers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#2-entertainment-venue-and-tour-managers",
    "aria-label": "2 entertainment venue and tour managers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.em, null, "2. Entertainment Venue and Tour Managers")), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "Besides the loud music and crowds that catapult sound levels to 115 dB, entertainment and event managers who work at clubs and lounges, as well as security staff that work concerts, are usually in danger for another reason entirely.")), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "And this would be the proximity to the speakers. Nightlife venues frequently place loud speakers all around the room, especially by the stage. Musicians playing live music with amps crank the sound levels even further. Couple these rising sound levels with screaming fans and long-term exposure can be reduced to immediate, short-term effects."))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/construction.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "3-construction-and-land-development",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#3-construction-and-land-development",
    "aria-label": "3 construction and land development permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.em, null, "3. Construction and Land Development")), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "Dangerous sounds abound at all construction and land development sites and projects. Many individuals who spend a lifetime building (no pun intended) a career in this field, experience noise-induced hearing loss as they inch towards retirement.")), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "For example, sandblasting can be 125 dB, gas chainsaws and jackhammers rise to 110 dB and just one pound of TNT is 180 dB, at a distance of 15 feet.")), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "Excessive noise is just par for the course at construction and mining sites, which can be offset by construction earmuffs and inner-ear noise dampeners."))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/car.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "4-racing-support-staff",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#4-racing-support-staff",
    "aria-label": "4 racing support staff permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.em, null, "4. Racing Support Staff")), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "When you think of motorbike races and Formula One races, hearing issues and liabilities are not the first thing that come to mind. However, amidst all the adrenaline-pumping races, support staff are always exposed to loud noises from roaring engines, sharp turns, and fans.")), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "Engine noises can get up to 135 dB and this is beyond the wind that often filters into the ears of support staff. Besides maintaining the race cars through the race, they must also shout to be heard above the noise."))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/grade.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "5-school-teachers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#5-school-teachers",
    "aria-label": "5 school teachers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.em, null, "5. School Teachers")), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "This one is not a tongue-in-cheek joke: school teachers run the risk of hearing loss due to prolonged exposure. Teachers who work in kindergarten classes and nurseries, especially with infants and young children, can find their hearing affected by music at play time or children yelling."))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/plane_closeup.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "6-military-personnel",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#6-military-personnel",
    "aria-label": "6 military personnel permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.em, null, "6. Military Personnel")), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "Low-flying aircrafts, machine guns and various other mechanical equipment, both in the field and on base, can affect military support staff and personnel. They don’t have to be in the line of fire to find that their hearing is often and profoundly affected over time."), "\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", React.createElement(_components.em, null, "Loud equipment and weapons, both testing and usage, can get up to 140 dB and this can cause irreparable damage to eardrums."))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/bus.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "7-bus-drivers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#7-bus-drivers",
    "aria-label": "7 bus drivers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.em, null, "7. Bus Drivers")), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "Bus drivers have a lot to contend with on their daily commutes. While they may know their routes inside and out, they are often exposed to loud traffic, packed buses with commuters crammed in and, most recently, the sound of loud commuter music.")), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "Though there are often signs discouraging the playing of loud music from personal devices in public spaces like a bus or train, these sounds can add pressure to the ears as well as to the head. It’s not uncommon for bus drivers with densely-populated routes to face headaches and earaches through their career.")), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "Commuters are at risk as well: to compensate for the noise around them, from other commuters in close proximity, as well as the operational sounds of a bus or train, individuals will crank up the volume to 100 dB. This puts them at risk for damage to the inner ear drum."))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/piano.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "8-musicians",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#8-musicians",
    "aria-label": "8 musicians permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.em, null, "8. Musicians")), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "Musicians of particular types of music can leave themselves open to profound hearing loss over the course of a (hopefully) star-studded career. But it’s not just rock and death metal varieties — those who both play in and conduct classical or jazz orchestras can also be vulnerable to noise-induced hearing loss.")), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "While there are special amphitheaters that are built for orchestral shows, music levels can span between 85 dB to 105 dB and make for a hazardous environment over time."))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/carpenter.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "9-carpentry-and-smithery",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#9-carpentry-and-smithery",
    "aria-label": "9 carpentry and smithery permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.em, null, "9. Carpentry and Smithery")), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "Carpentry and smithery are surging as trades and are making a comeback, especially in youth choosing to move to more rural areas and work with their hands as artisans.")), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "The sound of electric and gas chainsaws, hammering and demoing houses, and exposing oneself to the sounds of a clanging forge can put tradesmen at risk for hearing loss later in life."))), "\n", React.createElement(SideBySide, {
    headline: "<em></em>",
    copy: "",
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/tree.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "10-agriculture",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#10-agriculture",
    "aria-label": "10 agriculture permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.em, null, "10. Agriculture")), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "Yes, farmers aren’t safe either. While it’s true enough that rural farmers needn’t contend with urban noise pollution, their more serene lives still leave plenty of opportunity for noise-induced hearing loss.")), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "Just like construction crews and carpenters, farmers work the land with a range of machines and vehicles. In fact, a tractor can create noise up to 112 dB while combines can get up to 105 dB. Research conducted by the National Institute on Deafness and Other Communication Disorders shows that almost 75% of farmers experience mild to severe hearing loss later on in life."))), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Individuals prone to situations that result in hearing loss are more common than you think. And, as can be seen from these 10 professions, in places where you least expect it. Noise-induced hearing loss isn’t always so obvious or easy to spot."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So, next time you’re at your work, just close your eyes and listen: is there an influx of noise coming your way? This list didn’t mention another very obvious modern profession: call centers."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Clearly, dangers to one’s hearing are everywhere. So, it’s important to reduce and dampen noise, where possible."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
